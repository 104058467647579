export const en = {
  lang: "en",
  messages: {
    "Create {{appName}} account": "Create {{appName}} account",
    "USER GUIDE": "USER GUIDE",
    FAQs: "FAQs",
    "Text too small.": "Text too small.",
    "Invalid text.": "Invalid text.",
    "Invalid email address": "Invalid email address",
    "Email address too small": "Email address too small",
    "The password must contain 1 number 1 lower case letter and 1 upper case leter (may have special chars, spaces not allowed)":
      "The password must contain 1 number 1 lower case letter and 1 upper case leter (may have special chars, spaces not allowed)",
    "Password must be at least 8 characters long.":
      "Password must be at least 8 characters long.",
    "Upload file": "Upload file",
    "Uploading...": "Uploading...",
    "The username must only contain alphanumeric characters.":
      "The username must only contain alphanumeric characters.",
    "Username must be between 6 and 36 characters.":
      "Username must be between 6 and 36 characters.",
    Username: "Username",
    "New Password": "New Password",
    "Reset password": "Reset password",
    "<strong>The password must be at least 8 characters long, have 1 number 1 lower case letter and 1 uppercase letter.</strong><p>May have special characters.</p><p>Spaces are not allowed.</p>":
      "<strong>The password must be at least 8 characters long, have 1 number 1 lower case letter and 1 uppercase letter.</strong><p>May have special characters.</p><p>Spaces are not allowed.</p>",

    "Repeat password": "Repeat password",
    "The username is already taken, please choose other.":
      "The username is already taken, please choose other.",
    "Your account has been created successfully!":
      "Your account has been created successfully!",
    "Your account will be manually reviewed.":
      "Your account will be manually reviewed.",
    "You will receive an email as soon as the account becomes active.":
      "You will receive an email as soon as the account becomes active.",
    SEND: "SEND",
    "Finish creating {{appName}} account":
      "Finish creating {{appName}} account",
    "Thank you for confirming your email address.":
      "Thank you for confirming your email address.",
    "Please define your username and your identity verification.":
      "Please define your username and your identity verification.",
    "Please define your username.":
      "Please define your username.",
    "<strong>Case-sensitive username.</strong><p>If there is already a username equal to the one chosen we will add a number at the end.</p><p>Example: username1</p>":
      "<strong>Case-sensitive username.</strong><p>If there is already a username equal to the one chosen we will add a number at the end.</p><p>Example: username1</p>",
    "<strong>The password must contain: Numbers and Letters.</strong><p>May have special characters.</p><p>Spaces are not allowed.</p>":
      "<strong>The password must contain: Numbers and Letters.</strong><p>May have special characters.</p><p>Spaces are not allowed.</p>",
    "<strong>Case-sensitive username.</strong>":"<strong>Case-sensitive username.</strong>",
    "{{appName}} account": "{{appName}} account",
    Identification: "Identification",
    "Identification number": "Identification number",
    "Identification document - front": "Identification document - front",
    "Identification document - backside (if not already icluded on the file above)":
      "Identification document - backside (if not already icluded on the file above)",
    Validate: "Validate",
    Address: "Address",
    "Post code": "Post code",
    City: "City",
    "Proof of address": "Proof of address",
    "<p>Dated less than 3 months.</p>": "<p>Dated less than 3 months.</p>",
    "Registration successful!": "Registration successful!",
    "Please check your email to confirm your identity.":
      "Please check your email to confirm your identity.",
    '(Please check in the "Spam" box)': '(Please check in the "Spam" box)',
    "There are invalid fields in your request. Please check and re-submit:":
      "There are invalid fields in your request. Please check and re-submit:",
    "There was an error trying to process your registration. Please try again, or contact us.":
      "There was an error trying to process your registration. Please try again, or contact us.",
    Name: "Name",
    Surname: "Surname",
    Country: "Country",
    Currency: "Currency",
    "Expected monthly volume": "Expected monthly volume",
    "It only takes a minute": "It only takes a minute",
    "We detected that you already have a {{appName}} account and we now resend an email with your details.":
      "We detected that you already have a {{appName}} account and we now resend an email with your details.",
    'Please check your email to confirm your identity (Please check in the "Spam" box)':
      'Please check your email to confirm your identity (Please check in the "Spam" box)',
    "Register {{name}}": "Register {{name}}",
    "Interface page for register on <a href='{{url}}' target='_blank'>{{url}}</a> bettingplatform":
      "Interface page for register on <a href='{{url}}' target='_blank'>{{url}}</a> bettingplatform",
    "Password updated successfully!": "Password updated successfully!",
    "Login at <a href='{{url}}'>{{url}}</a> and start betting!":
      "Login at <a href='{{url}}'>{{url}}</a> and start betting!",
    "Login with the new password and start betting!":
      "Login with the new password and start betting!",
    "This username does not match the current password reset token.":
      "This username does not match the current password reset token.",
    "Welcome to Pro.{{name}}.com betting platform. The most respected Asian bookmakers, where winners are welcome.":
      "Welcome to Pro.{{name}}.com betting platform. The most respected Asian bookmakers, where winners are welcome.",
    "Register a new account on Pro.{{name}}":
      "Register a new account on Pro.{{name}}",
    "id-card-name-BR": "National ID or Passport",
    "id-card-name-EN": "Passport or Local ID Card",
    "id-card-name": "National ID or Passport",
    "Recover Password": "Recover Password",
    "The email you submited is not associated with any account":
      "The email you submited is not associated with any account",
    "Please submit the email associated to your account": 
      "Please submit the email associated to your account",
    "Request sent successfully":"Request sent successfully",
    "If we found an account with this email, an email was sent to you so you can proceed with resetting your password.":
      "If we found an account with this email, an email was sent to you so you can proceed with resetting your password.",
    "Phone Number":"Phone Number",
    "Number between 5 and 15 digits, can start with '+'":"Number between 5 and 15 digits, can start with '+'",
    "Invalid Pattern":"Invalid Pattern",
    "Invalid Cpf number": "Invalid Cpf number",
    "You must be 18 or older": "You must be 18 or older",
    "Birthdate": "Birthdate",
    "Invalid date": "Invalid date",
    "Note: in this document the full name, photo, date of birth, and National ID or Passport number must be visible.":
      "Note: in this document the full name, photo, date of birth, and National ID or Passport number must be visible.",
    "Accepted: National Id or Drivers license.<br>Note: in this document the full name, photo, date of birth, and National ID or Passport number must be visible.":
     "Accepted: National Id or Drivers license.<br>Note: in this document the full name, photo, date of birth, and National ID or Passport number must be visible.",
    "Confirm Password":"Confirm Password",
    "Confirm Email": "Confirm Email",
    "(DD-MM-YYYY)": "(DD-MM-YYYY)",
    "Day": "Day",
    "Month": "Month",
    "Year": "Year",
    "I confirm I have read and I agree to the <a id='{{TermsAndConditions}}' href='#'>Terms and Conditions</a> and <a id='{{PrivacyPolicy}}' href='#'>Privacy Policy</a> and confirm I am 18 years of age or over.":
    "I confirm I have read and I agree to the <a id='{{TermsAndConditions}}' href='#'>Terms and Conditions</a> and <a id='{{PrivacyPolicy}}' href='#'>Privacy Policy</a> and confirm I am 18 years of age or over.",
    "Terms And Conditions" : "Terms And Conditions",
    "Value already in use": "Value already in use",
    "Only_Crypto_deposit_available_in_{{ccy}}": "Only Crypto deposit is available in {{ccy}}",
    "It was not possible to use this validation code.":"It was not possible to use this validation code."
  },
};
